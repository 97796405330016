<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
                <Form label-position="left" :label-width="80" >
                    <FormItem label="id" v-if="data.id">
                        <Input v-model="data.id"  disabled></Input>
                    </FormItem>
                    <FormItem label="赛事" v-if="! $unit.filterGameids( [1,6], game_id )">
                        <Row>
                            <Col span="20" >
                                <tournamentSearch v-model="data.tournament" :game_id="game_id" ></tournamentSearch>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="比赛id">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.match_id" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="总局数" v-if=" $unit.filterGameids( [2, ], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.box" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="第几局" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.box_num" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[0]">
                        <Row>
                            <Col span="20" >
                                <teamSearch v-model="data.home" :game_id="game_id" ></teamSearch>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[1]">
                        <Row>
                            <Col span="20" >
                                <teamSearch v-model="data.away" :game_id="game_id" ></teamSearch>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="地图"  v-if="$unit.filterGameids( [2,], game_id )">
                        <Row>
                            <Col span="20" >
                                <mapSearch v-model="data.map" :game_id="game_id" ></mapSearch>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem :label="getTeamSide()[0] + 'Ban' " v-if="! $unit.filterGameids( [2,], game_id )">
                        <Row>
                            <Col span="20" >
                                <herosDragable v-model="data.home_bans" :game_id="game_id" ></herosDragable>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[0] + 'Pick' " v-if="! $unit.filterGameids( [2,], game_id )">
                        <Row>
                            <Col span="20" >
                                <herosDragable v-model="data.home_pick" :game_id="game_id" ></herosDragable>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[1] + 'Ban' " v-if="! $unit.filterGameids( [2,], game_id )">
                        <Row>
                            <Col span="20" >
                                <herosDragable v-model="data.away_bans" :game_id="game_id" ></herosDragable>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[1] + 'Pick' " v-if="! $unit.filterGameids( [2,], game_id )">
                        <Row>
                            <Col span="20" >
                                <herosDragable v-model="data.away_pick" :game_id="game_id" ></herosDragable>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[0] + '胜' " v-if="! $unit.filterGameids( [2,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.home_win" >
                                    <Option :value="1" key="1">是</Option>
                                    <Option :value="0" key="0">否</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[1] + '胜' " v-if="! $unit.filterGameids( [2,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.away_win" >
                                    <Option :value="1" key="1">是</Option>
                                    <Option :value="0" key="0">否</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[0] + '击杀' " v-if="! $unit.filterGameids( [2,], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.home_kills" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[1] + '击杀' " v-if="! $unit.filterGameids( [2,], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.away_kills" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem :label="getTeamSide()[0] + '胜回合' " v-if="$unit.filterGameids( [2,], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.home_score" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[1] + '胜回合' " v-if="$unit.filterGameids( [2,], game_id )" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.away_score" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label='半场比分' v-if="$unit.filterGameids( [2,], game_id )" >
                        <Row >
                            <Col span=20>
                                <Row>
                                    <Col span=4 style="text-align:center" class='text-main'>
                                    </Col>
                                    <Col span=8 style="text-align:center" class='text-main'>
                                        主胜回合
                                    </Col>
                                    <Col span=8 style="text-align:center" class='text-main'>
                                        客胜回合
                                    </Col>
                                </Row>
                                <Row v-for="(item, index) in score_s" :key="index">
                                    <Col span=4>
                                        <span class='text-main'> {{ {0:'上半场', 1:'下班场', 2:'加时'}[index] }} </span>
                                    </Col>
                                    <Col span=8>
                                        <InputNumber v-model="item[0]" placeholder=""></InputNumber>
                                    </Col>
                                    <Col span=8>
                                        <InputNumber v-model="item[1]" placeholder=""></InputNumber>
                                    </Col>
                                    <Col span="4" >
                                        <Icon type="md-close-circle" @click.native="delScores(index)"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Button type="primary" @click="addScores()">添加回合</Button>
                                </Row>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label='队伍评分' v-if="$unit.filterGameids( [2,], game_id )" >
                        <Row >
                            <Col span=20>
                                <Row v-if="data.rating && data.rating.length == 2">
                                    <Col span=10>
                                        <InputNumber v-model="data.rating[0]" placeholder="主队评分"></InputNumber>
                                    </Col>
                                    <Col span=10>
                                        <InputNumber v-model="data.rating[1]" placeholder="客队评分"></InputNumber>
                                    </Col>
                                    <Col span="4" >
                                        <Icon type="md-close-circle" @click.native="data.rating = [];"/>
                                    </Col>
                                </Row>
                                <Row v-else>
                                    <Button type="primary" @click="data.rating = [0, 0];">编辑</Button>
                                </Row>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label='首杀回合' v-if="$unit.filterGameids( [2,], game_id )" >
                        <Row >
                            <Col span=20>
                                <Row v-if="data.frist_kill && data.frist_kill.length == 2">
                                    <Col span=10>
                                        <InputNumber v-model="data.frist_kill[0]" placeholder="主队首杀回合数"></InputNumber>
                                    </Col>
                                    <Col span=10>
                                        <InputNumber v-model="data.frist_kill[1]" placeholder="客队首杀回合数"></InputNumber>
                                    </Col>
                                    <Col span="4" >
                                        <Icon type="md-close-circle" @click.native="data.frist_kill = [];"/>
                                    </Col>
                                </Row>
                                <Row v-else>
                                    <Button type="primary" @click="data.frist_kill = [0, 0];">编辑</Button>
                                </Row>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="比赛时间" v-if="! $unit.filterGameids( [1,6], game_id )">
                        <Row>
                            <Col span="20" >
                                <DatePicker 
                                    type="datetime" 
                                    format="yyyy-MM-dd HH:mm" 
                                    style="width: 100%"
                                    v-model="match_time"
                                >
                                </DatePicker>
                            </Col>
                        </Row>
                    </FormItem>
                    
                    <FormItem label="比赛时长" v-if="! $unit.filterGameids( [2,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Duration v-model="data.time_length"></Duration>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="一血" v-if="! $unit.filterGameids( [2,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.first_blood" >
                                    <Option :value="0" :key="0">未知</Option>
                                    <Option  v-if="data.home && data.home.id>0" :value="data.home.id" :key="data.home.id ">{{getTeamSide()[0] +': '+ data.home.name}}</Option>
                                    <Option  v-if="data.away && data.away.id>0" :value="data.away.id" :key="data.away.id ">{{getTeamSide()[1] +': '+ data.away.name}}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    
                    <FormItem label="一塔" v-if="! $unit.filterGameids( [2,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.first_tower" >
                                    <Option :value="0" :key="0">未知</Option>
                                    <Option v-if="data.home && data.home.id>0" :value="data.home.id" :key="data.home.id ">{{getTeamSide()[0] +': '+ data.home.name}}</Option>
                                    <Option v-if="data.away && data.away.id>0" :value="data.away.id" :key="data.away.id ">{{getTeamSide()[1] +': '+ data.away.name}}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="首肉山" v-if=" $unit.filterGameids( [3,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.first_roushan" >
                                    <Option :value="0" :key="0">未知</Option>
                                    <Option v-if="data.home && data.home.id>0" :value="data.home.id" :key="data.home.id " >{{getTeamSide()[0] +': '+ data.home.name}}</Option>
                                    <Option v-if="data.away && data.away.id>0" :value="data.away.id" :key="data.away.id ">{{getTeamSide()[1] +': '+ data.away.name}}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="首暴君" v-if=" $unit.filterGameids( [6,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.first_tyrants" >
                                    <Option :value="0" :key="0">未知</Option>
                                    <Option v-if="data.home && data.home.id>0" :value="data.home.id" :key="data.home.id " >{{getTeamSide()[0] +': '+ data.home.name}}</Option>
                                    <Option v-if="data.away && data.away.id>0" :value="data.away.id" :key="data.away.id ">{{getTeamSide()[1] +': '+ data.away.name}}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="首主宰" v-if=" $unit.filterGameids( [6,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.first_dominates" >
                                    <Option :value="0" :key="0">未知</Option>
                                    <Option v-if="data.home && data.home.id>0" :value="data.home.id" :key="data.home.id " >{{getTeamSide()[0] +': '+ data.home.name}}</Option>
                                    <Option v-if="data.away && data.away.id>0" :value="data.away.id" :key="data.away.id ">{{getTeamSide()[1] +': '+ data.away.name}}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="首五杀" v-if=" $unit.filterGameids( [1,6], game_id )">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.five_kills" >
                                    <Option :value="0" :key="0">未知</Option>
                                    <Option v-if="data.home && data.home.id>0" :value="data.home.id" :key="data.home.id ">{{getTeamSide()[0] +': '+ data.home.name}}</Option>
                                    <Option v-if="data.away && data.away.id>0" :value="data.away.id" :key="data.away.id ">{{getTeamSide()[1] +': '+ data.away.name}}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="首十杀" v-if=" ! $unit.filterGameids( [2], game_id )">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.ten_kills" >
                                    <Option :value="0" :key="0">未知</Option>
                                    <Option v-if="data.home && data.home.id>0" :value="data.home.id" :key="data.home.id ">{{getTeamSide()[0] +': '+ data.home.name}}</Option>
                                    <Option v-if="data.away && data.away.id>0" :value="data.away.id" :key="data.away.id ">{{getTeamSide()[1] +': '+ data.away.name}}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="首小龙" v-if=" $unit.filterGameids( [1,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.first_small_dragon" >
                                    <Option :value="0" :key="0">未知</Option>
                                    <Option v-if="data.home && data.home.id>0" :value="data.home.id" :key="data.home.id ">{{getTeamSide()[0] +': '+ data.home.name}}</Option>
                                    <Option v-if="data.away && data.away.id>0" :value="data.away.id" :key="data.away.id ">{{getTeamSide()[1] +': '+ data.away.name}}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="首大龙" v-if=" $unit.filterGameids( [1,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.first_big_dragon" >
                                    <Option :value="0" :key="0">未知</Option>
                                    <Option v-if="data.home && data.home.id>0" :value="data.home.id" :key="data.home.id ">{{getTeamSide()[0] +': '+ data.home.name}}</Option>
                                    <Option v-if="data.away && data.away.id>0" :value="data.away.id" :key="data.away.id ">{{getTeamSide()[1] +': '+ data.away.name}}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem :label="getTeamSide()[0] + '塔数' "  v-if=" $unit.filterGameids( [1,6], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.home_tower" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[1] + '塔数' "  v-if=" $unit.filterGameids( [1,6], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.away_tower" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem :label="getTeamSide()[0] + '小龙数' "  v-if=" $unit.filterGameids( [1,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.home_small_dragons" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[1] + '小龙数' "  v-if=" $unit.filterGameids( [1,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.away_small_dragons" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem :label="getTeamSide()[0] + '暴君数' "  v-if=" $unit.filterGameids( [6,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.home_tyrants" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[1] + '暴君数' "  v-if=" $unit.filterGameids( [6,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.away_tyrants" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[0] + '主宰数' "  v-if=" $unit.filterGameids( [6,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.home_dominates" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[1] + '主宰数' "  v-if=" $unit.filterGameids( [6,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.away_dominates" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem :label="getTeamSide()[0] + '经济' "  v-if=" $unit.filterGameids( [6,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.home_economy" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[1] + '经济' "  v-if=" $unit.filterGameids( [6,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.away_economy" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem :label="getTeamSide()[0] + '大龙数' "  v-if=" $unit.filterGameids( [1,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.home_big_dragons" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[1] + '大龙数' "  v-if=" $unit.filterGameids( [1,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.away_big_dragons" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[0] + '水晶数' "  v-if=" $unit.filterGameids( [1,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.home_crystal" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[1] + '水晶数' "  v-if=" $unit.filterGameids( [1,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.away_crystal" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem :label="getTeamSide()[0] + '厄塔汗' "  v-if=" $unit.filterGameids( [1,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.home_atakhan" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[1] + '厄塔汗' "  v-if=" $unit.filterGameids( [1,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.away_atakhan" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="首厄塔汗" v-if=" $unit.filterGameids( [1,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.first_atakhan" >
                                    <Option :value="0" :key="0">未知</Option>
                                    <Option v-if="data.home && data.home.id>0" :value="data.home.id" :key="data.home.id ">{{getTeamSide()[0] +': '+ data.home.name}}</Option>
                                    <Option v-if="data.away && data.away.id>0" :value="data.away.id" :key="data.away.id ">{{getTeamSide()[1] +': '+ data.away.name}}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem :label="getTeamSide()[0] + '巢虫数' "  v-if=" $unit.filterGameids( [1,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.home_voidgrub" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem :label="getTeamSide()[1] + '巢虫数' "  v-if=" $unit.filterGameids( [1,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.away_voidgrub" number ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="首巢虫" v-if=" $unit.filterGameids( [1,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.first_voidgrub" >
                                    <Option :value="0" :key="0">未知</Option>
                                    <Option v-if="data.home && data.home.id>0" :value="data.home.id" :key="data.home.id ">{{getTeamSide()[0] +': '+ data.home.name}}</Option>
                                    <Option v-if="data.away && data.away.id>0" :value="data.away.id" :key="data.away.id ">{{getTeamSide()[1] +': '+ data.away.name}}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="状态">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.status_id" >
                                    <Option v-for="item in $conf['games']['status']['map']" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="赛后数据" v-if="! $unit.filterGameids( [2,], game_id )">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.is_finished" >
                                    <Option :value="1" key="1">有</Option>
                                    <Option :value="0" key="0">无</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="删除">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.deleted" >
                                    <Option :value="1" key="1">是</Option>
                                    <Option :value="0" key="0">否</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                </Form>
            </TabPane>
        </Tabs>

        <Row :gutter="16" align="middle">
            <Col span="12" style="text-align: left" >
                <Button type="default"  @click="cancle" long size="default">取消</Button>
            </Col>
            <Col span="12" style="text-align: right">
                <Button type="primary"  @click="save" long size="default">保存</Button>
            </Col>
        </Row>
    </div>
</template>
<script>

import { getMatchSingleList, editMatchSingle } from '@/api/games/match_single';
import teamSearch from '../team/search.vue';
import tournamentSearch from '../tournament/search.vue';
import Duration from '../../basic/duration.vue';
import herosDragable from '../base/heros_dragable.vue';
import moment from 'moment';
import mapSearch from '../maps/search.vue';

export default {
    name: "match-edit",
    props: {
        game_id: Number,   // 运动id
        match_single_id: Number,   // 比赛id
    },
    components: {
        teamSearch,
        tournamentSearch,
        Duration,
        herosDragable,
        mapSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 1,
            type: 5, 
            spinShow: false,
            data: {
                game_id: self.game_id,
                home: {},
                away: {},
                tournament: {},
                match_time: parseInt( Date.parse(new Date())/1000 ),
                map: {},
            },
            locks: {},
        }
    },
    methods: {
        initDataScores(newValue){
            if (newValue && newValue.length > 0){
                var home = [];
                var away = [];
                newValue.map( (element, index) => {
                    home.push(element[0]);
                    away.push(element[1]);
                } )
                this.data.score_s = [ home, away ];
            }else{
                this.data.score_s = [];
            }
        },
        delScores(index){
            this.score_s.splice(index, 1);
            this.initDataScores(this.score_s);
        },
        addScores(){
            if (this.score_s . length < 3){
                this.score_s.push( [0, 0] );
                this.initDataScores(this.score_s);
            }else{
                this.$Message.error("不允许超过加时赛");
            }
        },
        // 获取分别的名称
        getTeamSide(){
            if (this.game_id == 1){
                return ['红', '蓝']
            }else if (this.game_id == 3 ){
                return ['天辉', '夜魇']
            }else if (this.game_id == 2 ){
                return ['主', '客']
            }else if (this.game_id == 6 ){
                return ['红', '蓝']
            }else{
                return ['', '']
            }
        },

        _init_data(){
            this.data = {
                game_id: this.game_id,
                home: {},
                away: {},
                tournament: {},
                map: {},
                match_time: parseInt( Date.parse(new Date())/1000 ),
            }
        },
        // 获取比赛的基础数据
        getData () {
            let self = this;
            this.spinShow = true;
            // 搜索日期
            if (self.match_single_id > 0){
                var params = {
                    game_id: self.game_id,
                    match_single_id: self.match_single_id,
                }
                getMatchSingleList( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            self.locks = response.data.data.locks;
                        }else{
                            this.data = {}
                        }
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self._init_data();
            }
            self.spinShow = false;
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        cancle(){
            let self = this;
            // self.match_single_id = 0; 不能直接修改probs传参
            self.emit("cancle")
        },
        initHeroIds(heros){
            var hero_ids =[];
            if (heros && heros.length > 0){
                heros.forEach(element => {
                    hero_ids.push(element.id)
                });
            }
            return hero_ids;
        },
        save() {
            let self = this;
            if (self.game_id == 3){
                var params = {
                    id: self.data.id,
                    game_id: self.data.game_id || self.game_id,
                    tournament_id: self.data.tournament.id,
                    match_id: self.data.match_id,
                    status_id: self.data.status_id,
                    box_num: self.data.box_num,
                    radiant_id: self.data.home.id,
                    dire_id: self.data.away.id,
                    radiant_win: self.data.home_win,
                    dire_win: self.data.away_win,
                    radiant_score: self.data.home_kills,
                    dire_score: self.data.away_kills,
                    match_time: self.data.match_time,
                    length_time: self.data.time_length,
                    first_blood: self.data.first_blood,
                    first_tower: self.data.first_tower,
                    first_roushan: self.data.first_roushan,
                    ten_kills: self.data.ten_kills,
                    is_finished: self.data.is_finished,
                    deleted: self.data.deleted,
                    // bp数据
                    radiant_bans: self.initHeroIds(self.data.home_bans),
                    radiant_pick: self.initHeroIds(self.data.home_pick),
                    dire_bans: self.initHeroIds(self.data.away_bans),
                    dire_pick: self.initHeroIds(self.data.away_pick),
                };
            }else if (self.game_id == 1){
                var params = {
                    id: self.data.id,
                    game_id: self.data.game_id || self.game_id,
                    match_id: self.data.match_id,
                    red_team_id: self.data.home.id,
                    blue_team_id: self.data.away.id,
                    status_id: self.data.status_id,
                    box_num: self.data.box_num,

                    red_tower: self.data.home_tower,
                    blue_tower: self.data.away_tower,
                    red_small_dragons: self.data.home_small_dragons,
                    blue_small_dragons: self.data.away_small_dragons,
                    red_big_dragons: self.data.home_big_dragons,
                    blue_big_dragons: self.data.away_big_dragons,
                    red_score: self.data.home_win,
                    blue_score: self.data.away_win,
                    red_kills: self.data.home_kills,
                    blue_kills: self.data.away_kills,
                    time_length: self.data.time_length,
                    first_blood: self.data.first_blood,
                    first_tower: self.data.first_tower,
                    five_kills: self.data.five_kills,
                    ten_kills: self.data.ten_kills,
                    first_small_dragon: self.data.first_small_dragon,
                    first_big_dragon: self.data.first_big_dragon,
                    red_crystal: self.data.home_crystal,
                    blue_crystal: self.data.away_crystal,
                    is_finished: self.data.is_finished,
                    deleted: self.data.deleted,
                    // bp数据
                    red_ban_squad: self.initHeroIds(self.data.home_bans),
                    red_squad: self.initHeroIds(self.data.home_pick),
                    blue_ban_squad: self.initHeroIds(self.data.away_bans),
                    blue_squad: self.initHeroIds(self.data.away_pick),
                    // 
                    red_atakhan: self.data.home_atakhan,
                    blue_atakhan: self.data.away_atakhan,
                    first_atakhan: self.data.first_atakhan,
                    red_voidgrub: self.data.home_voidgrub,
                    blue_voidgrub: self.data.away_voidgrub,
                    first_voidgrub: self.data.first_voidgrub,
                };
            }else if (self.game_id == 6){
                var params = {
                    id: self.data.id,
                    game_id: self.game_id,
                    match_id: self.data.match_id,
                    red_team_id: self.data.home.id,
                    blue_team_id: self.data.away.id,
                    status_id: self.data.status_id,
                    box_num: self.data.box_num,

                    red_score: self.data.home_win,
                    blue_score: self.data.away_win,
                    red_kills: self.data.home_kills,
                    blue_kills: self.data.away_kills,
                    red_tower: self.data.home_tower,
                    blue_tower: self.data.away_tower,
                    red_tyrants: self.data.home_tyrants,
                    blue_tyrants: self.data.away_tyrants,
                    red_dominates: self.data.home_dominates,
                    blue_dominates: self.data.away_dominates,
                    red_economy: self.data.home_economy,
                    blue_economy: self.data.away_economy,
                    game_time: self.data.time_length,
                    first_blood: self.data.first_blood,
                    first_tower: self.data.first_tower,
                    first_tyrants: self.data.first_tyrants,
                    first_dominates: self.data.first_dominates,
                    five_kills: self.data.five_kills,
                    ten_kills: self.data.ten_kills,
                    is_finished: self.data.is_finished,
                    deleted: self.data.deleted,
                    // bp数据
                    red_ban_squad: self.initHeroIds(self.data.home_bans),
                    red_squad: self.initHeroIds(self.data.home_pick),
                    blue_ban_squad: self.initHeroIds(self.data.away_bans),
                    blue_squad: self.initHeroIds(self.data.away_pick),
                };
            }else if (self.game_id == 2){
                this.initDataScores(self.score_s);// 生成score_s字段
                var params = {
                    id: self.data.id,
                    game_id: self.game_id,
                    tournament_id: self.data.tournament.id,
                    match_id: self.data.match_id,
                    box: self.data.box,
                    box_num: self.data.box_num,
                    home_team_id: self.data.home.id,
                    away_team_id: self.data.away.id,
                    map_id: self.data.map.id,
                    match_time: self.data.match_time,
                    status_id: self.data.status_id,
                    home_score: self.data.home_score,
                    away_score: self.data.away_score,
                    score_s : self.data.score_s,
                    rating: self.data.rating,
                    frist_kill: self.data.frist_kill,
                    deleted: self.data.deleted,
                };
            }else{
                return
            }
            // console.log(params);
            editMatchSingle(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
    },
    watch: {
        // match_single_id(value){
        //     let self = this;
        //     if (value){
        //         self.getData();
        //     };
        // },
        'match_single_id': {
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    self.getData();
                };
            },
            deep: false,
	        immediate: false,  // 深度触发
        },
        // end 
    },
    computed: {
        
        match_time: {
            get: function () {
                if (this.data.match_time){
                    return moment.unix(this.data.match_time).toDate();
                }else{
                    return parseInt( Date.parse(new Date())/1000 ) ;
                }
            },
            set: function (newValue) {
                this.data.match_time = moment(newValue).unix();
            }
        },
        score_s: {
            deep: true,
            immediate: true,
            get: function () {
                if (this.data.score_s && this.data.score_s.length == 2){
                    let home = this.data.score_s[0];
                    let away = this.data.score_s[1];
                    var zip_scores =  home.map(function(e, i) {
                        return [e, away[i]];
                        // return {}
                    });
                    // console.log(zip_scores)
                    return zip_scores;
                }else{
                    return [];
                }
            },
            // set: function (newValue) {
            //     this.initDataScores(newValue);
            // }
        }
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>